import { vuexfireMutations, firestoreAction } from 'vuexfire'
import Vue from 'vue';
import Vuex from 'vuex';

import {
  firestore,
  fauth,
  fauthPersistence
} from './firebase'
import SoundManager from './sound-manager';

import moment from 'moment'
import axios from 'axios'
axios.defaults.withCredentials = true;

Vue.use(Vuex);

const REJECT_REASONS = {
  1001: '店家忙碌中，無法接此訂單',
  1002: '即將打烊，無法接此訂單',
  1003: '原料／備貨不足，無法接此訂單',
  1004: '無外送人員，無法接此訂單',
  2001: '店家忙碌中，無法處理訂單',
  2002: '即將打烊，無法處理訂單',
  2003: '原料／備貨不足，無法處理訂單',
  2004: '無外送人員，無法處理訂單',
  2010: '顧客來電取消',
};
const STATUS_SORTING_PRIORITIES = {
  new: 1000000000000, accepted: 1000000000000,
  done: 9000000000000, cancelled: 9100000000000, rejected: 9200000000000,
  lala_assigning_driver: 6000000000000,
  lala_on_going: 6100000000000,
  lala_picked_up: 6200000000000,
  lala_assigning_driver: 9300000000000,
};

export default new Vuex.Store({
  state: {

    /// Shop and user and login-related states.
    shopId: 0,
    shopName: '',
    hash: '',
    shopConfig: {},

    uiMode: localStorage.getItem('qcpos-config-ui-mode') || 'm',

    /// Order data.
    orders: [],//NOTE: This will be synced with Firestore (via Vuexfire API).
    orderListParams: {
      searchString: '',
      filterType: 'pending'
    },

    cache: {},//NOTE: Cache for caching cart data per order, to save making requests.

    /// Global setting varaibles.
    settings: {
      sortMode: localStorage.getItem('qcpos-settings-sort-mode') || 'status',
      sound: {
        enabled: [null, 'true'].indexOf(localStorage.getItem('qcpos-settings-sound-enabled')) >= 0,
        incoming: localStorage.getItem('qcpos-settings-sound-incoming') || 'piano',
        pending: localStorage.getItem('qcpos-settings-sound-pending') || 'piano'
      },

      cheats: {
        submitLogsEnabled: false,
        preSyncDisabled: false,
      }
    },

    notification: {
      evt: 'error',
      msg: ''
    },
    version: process.env.PACKAGE_VERSION,
    versionChanges: [],
    showVersionTip: localStorage.getItem('qcpos-config-show-version-tip') || 'true'
  },

  mutations: {
    ...vuexfireMutations,

    /// Initialize some stored states. NOTE: This is important especially when pages are being forcibly reloaded.
    loadUserSession(state) {
      if (state.shopId)//This indicate states about user session have been initialized, no need to load again.
        return;

      // Look for the store user session data in LocalStorage.
      let user = localStorage.getItem('qcpos-session-user');
      if (!user)
        return;//NOTE: No user session store, just return and let router continue.

      // Got user session, parse it (since it should be a JSON-formatted string).
      console.warn(`initState: will init state variables using user session data stored in local-storage.`, user);
      try {
        user = JSON.parse(user);
      } catch(exception) {
        alert(`Cannot parse user session data!`);
        return;
      }

      state.shopId = user.shopId;
      state.shopName = user.shopName;
      state.hash = user.hash;
      state.shopConfig = user.shopConfig || { };
    },

    /// Set/reset states and local-storage upon login/logout.
    login(state, args) {
      state.shopId = args.user.shopId;
      state.shopName = args.user.shopName;
      state.hash = args.user.hash;
      state.shopConfig = args.user.shopConfig || { };
      
      state.uiMode = args.uiMode;

      localStorage.setItem('qcpos-session-user', JSON.stringify(args.user));
      localStorage.setItem('qcpos-session-username', args.username);
      localStorage.setItem('qcpos-session-password', args.password);
      localStorage.setItem('qcpos-config-ui-mode', args.uiMode);
    },
    logout(state) {
      state.shopId = 0;
      state.shopName = '';
      state.cache = {};
      
      localStorage.removeItem('qcpos-session-user');

      // FIXME: These will force client to remove obsoleted keys in store.
      localStorage.removeItem('qcpos-session-shop-id');
      localStorage.removeItem('qcpos-session-shop-name');
      localStorage.removeItem('qcpos-session-hash');

      // Reset some other parameters.
      state.orderListParams.filterType = 'pending';
      state.orderListParams.searchString = '';
    },

    /// Sync over Firestore listener status.
    toggleFirestoreSubscription(state, _enable) {
      state.isFirestoreSubscribed = _enabled;
    },

    /// Set order-list parameters.
    setFilterType(state, _type) {//Determined by main page bottom-navigation.
      state.orderListParams.filterType = _type;
    },
    setSearchString(state, _str) {
      state.orderListParams.searchString = _str;
    },
    setSortMode(state, _mode) {
      localStorage.setItem('qcpos-settings-sort-mode', _mode);
      state.settings.sortMode = _mode;
    },
    setSoundSetting(state, args) {
      localStorage.setItem('qcpos-settings-sound-enabled', args.enabled? 'true': 'false');
      localStorage.setItem('qcpos-settings-sound-incoming', args.incoming);
      localStorage.setItem('qcpos-settings-sound-pending', args.pending);
      state.settings.sound = args;

      //TODO: Use SoundManager to change sound.
      let sm = SoundManager.get();
      sm.enable(args.enabled);
      if (args.enabled) {
        sm.loadTrack('incoming', args.incoming);
        sm.loadTrack('pending', args.pending);
      }
    },

    /// Cheats related
    setCheatSettings(state, args) {
      state.settings.cheats.submitLogsEnabled = args.submitLogsEnabled;
      state.settings.cheats.preSyncDisabled = args.preSyncDisabled;
      state.settings.cheats.dates = args.dates;
    },

    /// Event-triggered update of global notification.
    notify(state, args) {
      state.notification = args;//NOTE: {evt: ..., msg: ...} 
    },

    /// Event-triggered update of remote version change.
    setVersion(state, args) {
      state.version = args.number;
      state.versionChanges = args.changes;
    },
    setVersionTipEnabled(state, _enabled) {
      state.showVersionTip = _enabled == true? 'true': 'false';
      localStorage.setItem('qcpos-config-show-version-tip', state.showVersionTip);
    }
  },

  getters: {
    orders(state) {
      return state.orders
      // Apply filtering.
      .filter(o => {
        let searchString = state.orderListParams.searchString;
        // Handle searching.
        if (searchString.length) {
          if (o.order_number == parseInt(searchString)) return true;
          if (searchString.length < 3) return false;
          let searchPhoneNumber = searchString.length >= 10?
            searchString.slice(1)://FIXME: Remove the heading '0'.
            searchString;
          let userPhoneSubstring = o.phone?
            o.phone.substring(o.phone.length - searchPhoneNumber.length): '';
          if (userPhoneSubstring === searchPhoneNumber) return true;
          // if (o.phone && o.phone.includes(searchPhoneNumber)) return true;
          return false;
        }

        // No searching applied.
        let startOfTomorrowTs = moment().add(1, 'days').startOf('day').unix();

        switch (state.orderListParams.filterType) {
          case 'pending':
            return [
              'new', 'accepted',
              'lala_assigning_driver', 'lala_on_going', 'lala_picked_up'
            ].indexOf(o.status) >= 0;
          case 'done':
            return [
              'done', 'cancelled', 'rejected',
              'lala_completed', 'lala_cancelled', 'lala_rejected'
            ].indexOf(o.status) >= 0;
          case 'future':
            return o.take_meal_time >= startOfTomorrowTs;
          case 'all': return true;
          default: return false;
        }
      })

      // Apply sorting.
      .sort((a, b) => {
        switch (state.settings.sortMode) {
          case 'status':
            return (STATUS_SORTING_PRIORITIES[a.status] + a.take_meal_time)
              - (STATUS_SORTING_PRIORITIES[b.status] + b.take_meal_time);
          case 'number': return a.order_number - b.order_number;
          case 'time':
          default:
            return a.take_meal_time - b.take_meal_time;
        }
      });
    },

    allOrders(state) {
      return state.orders;
    },

    soundSetting(state) {
      return state.settings.sound;
    },

    uiMode(state) {
      return state.uiMode;
    },

    isRetail(state) {
      return state.shopConfig.isRetail || false;
    },
    mealTypeLabels(state) {
      return state.shopConfig.mealTypeLabels || [ '內用', '外帶', '外送' ];
    },

    cheats(state) {
      return state.settings.cheats;
    }
  },

  actions: {
    /**
     * Veuxfire bindings
     */
    bindOrders: firestoreAction(({ state, bindFirestoreRef }) => {
      //FIXME: set to bind with target shop's order list.
      return bindFirestoreRef('orders', firestore.collection(`shops/${state.shopId}/orders`), {
        serialize: doc => {
          return Object.defineProperty(doc.data(), 'isFirstBatch', {
            value: true
          });
        }
      })
    }),
    unbindOrders: firestoreAction(({ state, unbindFirestoreRef }) => {
      unbindFirestoreRef('orders');
    }),

    /**
     * Auth methods
     */
    basicSignin({ state, commit }, userCredential) {
      let user;
      // Ask QC-APIS backend for Firebase custom token.
      return axios.post(`${process.env.VUE_APP_APIHOST}/qcpos/signin`, userCredential)
      .then(response => {
        // console.log(`basicSignin succeeded`, response.data);
        user = response.data.user;
        
        let token = user.token;

        // Do Firebase signin. WARNING: Session will be persistent by LocalStorage.
        return fauth.setPersistence(fauthPersistence.LOCAL)
        .then(() => {
          return fauth.signInWithCustomToken(token);
        });
      })
      .then(() => {

        // commit('login', {
        //   shopId: user.shopId,
        //   shopName: user.shopName,
        //   username: userCredential.username,
        //   password: userCredential.password,
        //   hash: user.hash,
        //   uiMode: userCredential.uiMode || 'm',
        //   shopConfig: user.shopConfig
        // });
        commit('login', {
          user,
          username: userCredential.username,
          password: userCredential.password,
          uiMode: userCredential.uiMode || 'm'
        });

      });
    },
    signout({ state, commit }) {
      // Attempt to unbind (Vuexfire)
      this.dispatch('unbindOrders').then(() => {});

      // Clear session and localStorage states.
      commit('logout');

      // First, do a Firebase signout.
      return fauth.signOut()
      .then(() => {
        // Then do a QC-APIS signout.
        return axios.post(`${process.env.VUE_APP_APIHOST}/signout`);
      });
    },
    checkLineAuthentication({ state, commit }) {
      return new Promise()
    },

    /**
     * Action of making requests to QC-APIS.
     */
    requestSync({ state, commit }) {
      let from, to;
      if (state.settings.cheats.dates) {
        from = moment(state.settings.cheats.dates[0], 'YYYY-MM-DD').startOf('day').unix();
        to = moment(state.settings.cheats.dates[1], 'YYYY-MM-DD').endOf('day').unix();
      }
      else {
        from = moment().startOf('day').unix();
        to = moment().add(1, 'months').endOf('day').unix();//WARNING: Look for 1-month future orders.
      }
      

      //WARNING: If cheat requires no pre-sync, just return a promise to skip it.
      if (state.settings.cheats.preSyncDisabled === true) {
        console.warn(`Cheat: Firestore sync is disabled.`);
        return new Promise((resolve) => { resolve(); })
      }
      
      // Otherwise, do a serious Firestore sync~
      return axios.get(
        `${process.env.VUE_APP_APIHOST}/qcpos/firebase/sync?shopId=${state.shopId}&sort=id:desc`
        + `&from=${from}&to=${to}&h=${state.hash}`
        + `&includeNotes=true&includeStatus=true&timeRangeAttr=take_meal_time`
      )
      .then(response => {
        return response.data;//This is the object-map of the synced orders.
      })
      .catch(err => {
        console.error(`requestSync error`, err);
        throw err;
      });
    },
    
    updateOrderStatus({ state, commit }, args) {
      // Determine reject-reason.
      let rejectReason = REJECT_REASONS[args['reason'] || -1];

      // Request to update order status.
      return axios.post(
        `${process.env.VUE_APP_APIHOST}/qcpos/orders/${args.orderId}/${args.status}`,//WARNING: 'orderId' here must be like 0000xxxxxx.
        { reason: rejectReason }
      )
      .then(response => {
        console.info(`An order has been updated: ${args.status}.`);
        return args;
      })
      .catch(err => {
        console.error('Cannot update order status', err);
        throw err;//Forward the error.
      });
    },
    
    markRead({ state, commit }, args) {
      // console.warn(`Marking as read on shops/${state.shopId}/orders/${args.orderId}...`);
      firestore.doc(`shops/${state.shopId}/orders/${args.orderId}`)
        .set({ read: true }, { merge: true })
        .then(() => {}).catch(err => { console.error('markRead error', err) });
    },

    fetchOrderCart({ state, commit }, args) {
      // If the cart data has already been requested and cached before...
      if (args.orderId in state.cache) {
        console.warn(`fetchOrderCart: take the data from cache for order ${args.orderId}...`);
        return new Promise((resolve, reject) => {
          resolve(state.cache[args.orderId]);
        });
      }

      // Otherwise, we need to ask for it.
      return axios.get(
        `${process.env.VUE_APP_APIHOST}/qcpos/orders/${args.orderId}/cart?includeOrderData=true`
      )
      .then(response => {
        // Save to cache.
        state.cache[args.orderId] = response.data;
        return response.data;
      })
      .catch(err => {
        console.error(`Cannot fetch cart data of order ${args.orderId}`, err);
        throw err;
      });
    },

    requestReprint({ state, commit }, args) {
      return axios.post(`${process.env.VUE_APP_APIHOST}/qcpos/orders/${args.orderId}/reprint`)
      .then(response => {
        console.warn(`Has requested to reprint order ${args.orderId}.`);
        return args;
      })
      .catch(err => {
        console.error('Cannot request order reprint', err);
        throw err;//Forward the error.
      });
    },

    requestDeliveryCancel({ state, commit }, args) {
      return axios.delete(`${process.env.VUE_APP_APIHOST}/qcpos/orders/cancel-lala/${args.orderId}`)
      .then(response => {
        console.warn(`Has requested to cancel the Lalamove delivery of order ${args.orderId}.`);
        return args;
      })
      .catch(err => {
        console.error('Cannot request delivery cancellation', err);
        throw err;//Forward the error.
      });
    },

    fetchDeliveryDriverInfo({ state, commit }, args) {
      return axios.get(`${process.env.VUE_APP_APIHOST}/qcpos/orders/${args.orderId}/driver`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.error('Cannot fetch delivery driver info', err);
        throw err;//Forward the error.
      });
    },


    /// Utilities
    checkForUpdate({ state, commit }) {
      let ver_breakdown = v => {
        let temp = v.split('.');
        return { major: parseInt(temp[0]), minor: parseInt(temp[1]), patch: parseInt(temp[2]) };
      };
      let package_ver = process.env.PACKAGE_VERSION;
      return axios.get(`${process.env.VUE_APP_APIHOST}/qcpos/latest-version`)
      .then(response => {
        
        let latestVer = response.data;
        let currentVer = ver_breakdown(package_ver);
        let l = latestVer.major * 10000 + latestVer.minor * 100 + latestVer.patch;
        let c = currentVer.major * 10000 + currentVer.minor * 100 + currentVer.patch;
        console.warn('Local', c, 'remote', l);

        let latestVerStr = `${latestVer.major}.${latestVer.minor}.${latestVer.patch}`;

        // Set version info if the remote version is larger or equal.
        if (l >= c) {
          commit('setVersion', { number: latestVerStr, changes: latestVer.changes || [] });
        }

        //NOTE: If remote version is newer...
        if (l > c) {
          console.warn('checkForUpdates: remote version is newer!', currentVer, '->', latestVer);
          commit('setVersionTipEnabled', true);
          return latestVerStr;
        }
        // Otherwise return null.
        return null;
      })
      .catch(err => {
        console.error(`Cannot get version number`, err);
        return null;
      });
    },
  }
})
