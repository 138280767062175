<template>
  <v-app>
    <!-- <v-main> -->
      <router-view></router-view>
    <!-- </v-main> -->

    <!-- <v-snackbar top v-bind="snackbar.attrs"
      v-model="snackbar.show">
      {{ snackbar.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="test">Close</v-btn>
      </template>
    </v-snackbar> -->
  </v-app>
</template>

<script>
import SoundManager from './sound-manager';

export default {
  name: 'App',

  components: {
  },

  created() {
    this.$http.interceptors.response.use(undefined, err => {
      return new Promise((resolve, reject) => {
        console.error(`[HTTP INTERCEPTOR] Caught error response`, err.response.status);
        if (err.response && [ 401, 403 ].indexOf(err.response.status) >= 0) {
          alert(`登入時效逾期，您需要重新登入。（${err.response.status}）`);
          this.$store.dispatch('signout')
          .finally(() => {
            this.$router.push('/auth');
          });
        }
        throw err;
      });
    });
  },

  mounted() {
    this.initSound();
  },

  methods: {
    initSound() {
      let sm = SoundManager.get();
      let setting = this.soundSetting;
      console.log(`[App.vue] initSound`, setting);
      sm.loadTrack('incoming', setting.incoming);
      sm.loadTrack('pending', setting.pending);
      sm.init();
      sm.enable(setting.enabled);
    }
  },

  data() {
    return {
      // snackbar: {
      //   show: false,
      //   msg: '',
      //   attrs: { }
      // },

      // hidden: '',
      // visibilityChange: ''
    };
  },

  computed: {
    shopId() {
      return this.$store.state.shopId;
    },
    shopName() {
      return this.$store.state.shopName;
    },
    soundSetting() {
      return this.$store.getters.soundSetting;
    },

    // globalNotification() {
    //   return this.$store.state.notification;
    // }
  },

  watch: {
    // globalNotification(v) {
    //   console.warn(`global notification updated`, v);
    //   this.snackbar.attrs = v.attrs;
    //   this.snackbar.msg = v.msg;
    //   this.snackbar.show = true;
    // }
  }
};
</script>

<style>
.v-content {
  background: #F3F3F3;
}
</style>
