function SoundManager() {
  this.enabled = true;
  
  this.tracks = {};

  this.currentTrackName = null;
}
SoundManager.priority = ['pending', 'incoming'];
SoundManager.trackSrc = {
  incoming: {
    basic: 'chime.mp3', piano: 'incoming_orders.mp3'
  },
  pending: {
    basic: 'dingaling.mp3', piano: 'pending_orders.mp3'
  }
};
SoundManager.instance = undefined;
SoundManager.get = function() {
  if (!SoundManager.instance)
    SoundManager.instance = new SoundManager();
  return SoundManager.instance;
};

SoundManager.prototype.init = function() {
  document.body.addEventListener('touchstart', this.soundStarter, false);
  document.body.addEventListener('mousedown', this.soundStarter, false);
};

SoundManager.prototype.soundStarter = function() {
  // console.warn(`[SoundManager] soundStarter triggered.`);
  for (let trackName in this.tracks) {
    let track = this.tracks[trackName];
    track.play();
    track.pause();
    track.currentTime = 0;
  }
  document.body.removeEventListener('touchstart', this.soundStarter, false);
  document.body.removeEventListener('mousedown', this.soundStarter, false);
};

SoundManager.prototype.enable = function(_enable) {
  if (!_enable)
    this.stop();
  this.enabled = _enable;
};

SoundManager.prototype.loadTrack = function(_track, _type) {
  let src = SoundManager.trackSrc[_track][_type];
  if (!src) return;
  this.tracks[_track] = new Audio(`${process.env.BASE_URL}sound/${src}`);
};

SoundManager.prototype.play = function(_track, _loop) {
  if (!this.enabled) return;
  if (!this.isTrackPrioritized(_track)) return;
  
  console.warn(`[SoundManager] Will play track ${_track} (loop? ${_loop === true})`);

  let self = this;
  if (this.currentTrackName) this.stop();
  let track = this.tracks[_track];
  if (!track) return;
  track.loop = _loop || false;
  track.onended = function() { self.currentTrackName = null; };
  this.currentTrackName = _track;
  track.play();
};

SoundManager.prototype.stop = function() {
  if (!this.enabled) return;
  if (!this.currentTrackName) return;
  let track = this.tracks[this.currentTrackName];
  track.pause();
  track.currentTime = 0;
  this.currentTrackName = null;
};

SoundManager.prototype.isTrackPrioritized = function(_track) {
  if (!this.currentTrackName) return true;
  return SoundManager.priority.indexOf(_track) < SoundManager.priority.indexOf(this.currentTrackName);
};


export default SoundManager;